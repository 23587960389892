import React from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import ReactMarkdown from "react-markdown";

const CompanyDetails = () => {
	return (
		<Layout>
			<GatsbySeo
				title="Company Details"
				description="Find company and bank details for Active Travels Pte Ltd."
			/>
			<div className="my-20">
				<h1 className="font-bold">Company and Bank Details</h1>
				<h2 className="font-light text-primary max-w-3xl mb-14">
					Active Travels Pte Ltd is a Singapore-based travel company specializing in
					curated cycling experiences worldwide. Below, you'll find our official
					company address and bank details for various currencies.
				</h2>
			</div>
			<div className=" p-6 mx-auto text-center prose">
				<h2 className="text-xl font-bold text-gray-800">Active Travels Pte Ltd</h2>
				<p className="text-gray-600 mt-2">32 Pekin Street</p>
				<p className="text-gray-600">#05-01</p>
				<p className="text-gray-600">Singapore (048762)</p>

				{/* Table of Contents */}
				<div className="p-6 mx-auto text-center prose border border-gray-200 rounded-lg shadow-md mb-6">
					<h2 className="text-lg font-semibold text-gray-800">Table of Contents</h2>
					<ul className=" text-left mx-auto w-fit">
						<li>
							<a href="#usd" className="text-blue-600 hover:underline">
								USD Payment Details
							</a>
						</li>
						<li>
							<a href="#gbp" className="text-blue-600 hover:underline">
								GBP Payment Details
							</a>
						</li>
						<li>
							<a href="#aud" className="text-blue-600 hover:underline">
								AUD Payment Details
							</a>
						</li>
						<li>
							<a href="#cad" className="text-blue-600 hover:underline">
								CAD Payment Details
							</a>
						</li>
					</ul>
				</div>
				<div id="usd" className="mt-6 text-left">
					<h3 className="text-lg font-semibold text-gray-800">
						<span className="text-primary">
							Active Travels Pte. Ltd.'s USD Payment Details
						</span>
					</h3>
					<p className="text-gray-600 mt-2">
						Active Travels Pte. Ltd. is using Wise Business to receive USD payments.
					</p>
					<p className="text-gray-600 mt-2 font-semibold">
						If you're paying from a bank account:
					</p>
					<ul className=" list-inside text-gray-600">
						<li>In the US — make a domestic payment</li>
						<li>
							Outside the US — make an international payment using SWIFT details
						</li>
					</ul>

					<h4 className="text-md font-semibold text-gray-800 mt-4">
						Domestic payments from bank accounts in the US
					</h4>
					<p className="text-gray-600">
						Make a domestic payment to send USD from a bank account in the US.
					</p>

					<p className="text-gray-600 mt-2">
						<strong>Account holder:</strong> Active Travels Pte. Ltd.
					</p>
					<p className="text-gray-600">
						<strong>Account number:</strong> 897760143520193
					</p>
					<p className="text-gray-600">
						<strong>Account type:</strong> Checking
					</p>
					<p className="text-gray-600">
						<strong>Routing number:</strong> 084009519 (For ACH and wire transfers)
					</p>
					<p className="text-gray-600">
						<strong>SWIFT/BIC:</strong> TRWIUS35XXX (Use it only if you are making
						international payment outside the US)
					</p>

					<h4 className="text-md font-semibold text-gray-800 mt-4">
						Bank name and address
					</h4>
					<p className="text-gray-600">Column National Association</p>
					<p className="text-gray-600">
						30 W. 26th Street, Sixth Floor, New York, NY 10010, United States
					</p>

					<h4 className="text-md font-semibold text-gray-800 mt-4">
						Important to note
					</h4>
					<ul className=" list-inside text-gray-600">
						<li>The same routing number works for ACH and wire transfers.</li>
						<li>
							Wire transfers may cost you 20-30 USD, depending on your bank — they
							usually take 1 working day to arrive.
						</li>
						<li>
							ACH transfers are low-cost to send — they usually take 2-3 working days
							to arrive.
						</li>
					</ul>
				</div>
				<hr className="h-2 bg-black"></hr>
				<div id="gbp" className="mt-6 text-left">
					<h3 className="text-lg font-semibold text-gray-800">
						<span className="text-primary">
							Active Travels Pte. Ltd.'s GBP Payment Details
						</span>
					</h3>
					<p className="text-gray-600 mt-2">
						Active Travels Pte. Ltd. is using Wise Business to receive GBP payments.
					</p>
					<p className="text-gray-600 mt-2 font-semibold">
						If you're paying from a bank account:
					</p>
					<ul className=" list-inside text-gray-600">
						<li>In the UK — make a domestic payment</li>
						<li>
							Outside the UK — make an international payment using SWIFT details
						</li>
					</ul>

					<h4 className="text-md font-semibold text-gray-800 mt-4">
						Domestic payments from bank accounts in the UK
					</h4>
					<p className="text-gray-600">
						Make a domestic payment to send GBP from a bank account in the UK.
					</p>

					<p className="text-gray-600 mt-2">
						<strong>Account holder:</strong> Active Travels Pte. Ltd.
					</p>
					<p className="text-gray-600">
						<strong>Sort code:</strong> 23-08-01
					</p>
					<p className="text-gray-600">
						<strong>Account number:</strong> 41553966
					</p>
					<p className="text-gray-600">
						<strong>IBAN:</strong> GB61 TRWI 2308 0141 5539 66
					</p>
					<p className="text-gray-600">
						<strong>SWIFT/BIC:</strong> TRWIGB2LXXX (Use it only if you are making
						international payment outside the UK)
					</p>

					<h4 className="text-md font-semibold text-gray-800 mt-4">
						Bank name and address
					</h4>
					<p className="text-gray-600">Wise</p>
					<p className="text-gray-600">56 Shoreditch High Street</p>
					<p className="text-gray-600">London, E1 6JJ</p>
					<p className="text-gray-600">United Kingdom</p>

					<h4 className="text-md font-semibold text-gray-800 mt-4">
						Important to note
					</h4>
					<ul className=" list-inside text-gray-600">
						<li>
							Domestic GBP payments usually take 1 working day, or less, to arrive.
						</li>
						<li>
							Although Wise isn’t a bank, you can use these details if you need a bank
							name and address, and we’ll process the transfer.
						</li>
					</ul>
				</div>
				<hr className="h-2 bg-black"></hr>
				<div id="aud" className="mt-6 text-left">
					<h3 className="text-lg font-semibold text-gray-800">
						<span className="text-primary">
							Active Travels Pte. Ltd.'s AUD Payment Details
						</span>
					</h3>
					<p className="text-gray-600 mt-2">
						Active Travels Pte. Ltd. is using Wise Business to receive AUD payments.
					</p>
					<p className="text-gray-600 mt-2 font-semibold">
						If you're paying from a bank account:
					</p>
					<ul className=" list-inside text-gray-600">
						<li>In the Australia — make a domestic payment</li>
						<li>
							Outside the Australia — make an international payment using SWIFT details
						</li>
					</ul>

					<h4 className="text-md font-semibold text-gray-800 mt-4">
						Domestic payments from bank accounts in the Australia
					</h4>
					<p className="text-gray-600">
						Make a domestic payment to send AUD from a bank account in the Australia.
					</p>

					<p className="text-gray-600 mt-2">
						<strong>Account holder:</strong> Active Travels Pte. Ltd.
					</p>
					<p className="text-gray-600">
						<strong>Account number:</strong> 225006834
					</p>
					<p className="text-gray-600">
						<strong>BSB code:</strong> 774-001
					</p>
					<p className="text-gray-600">
						<strong>SWIFT/BIC:</strong> TRWIAUS1XXX (Use it only if you are making
						international payment outside the Australia)
					</p>

					<h4 className="text-md font-semibold text-gray-800 mt-4">
						Bank name and address
					</h4>
					<p className="text-gray-600">Wise Australia Pty Ltd</p>
					<p className="text-gray-600">Suite 1, Level 11, 66 Goulburn Street</p>
					<p className="text-gray-600">Sydney NSW 2000</p>
					<p className="text-gray-600">Australia</p>
				</div>
				<hr className="h-2 bg-black"></hr>
				<div id="cad" className="mt-6 text-left">
					<h3 className="text-lg font-semibold text-gray-800">
						<span className="text-primary">
							Active Travels Pte. Ltd.'s CAD Payment Details
						</span>
					</h3>
					<p className="text-gray-600 mt-2">
						Active Travels Pte. Ltd. is using Wise Business to receive CAD payments.
					</p>
					<p className="text-gray-600 mt-2 font-semibold">
						If you're paying from a bank account:
					</p>
					<ul className=" list-inside text-gray-600">
						<li>In the Canada — make a domestic payment</li>
						<li>
							Outside the Canada — make an international payment using SWIFT details
						</li>
					</ul>

					<h4 className="text-md font-semibold text-gray-800 mt-4">
						Domestic payments from bank accounts in the Canada
					</h4>
					<p className="text-gray-600">
						Make a domestic payment to send CAD from a bank account in the Australia.
					</p>

					<p className="text-gray-600 mt-2">
						<strong>Account holder:</strong> Active Travels Pte. Ltd.
					</p>
					<p className="text-gray-600">
						<strong>Account number:</strong> 200116577358
					</p>
					<p className="text-gray-600">
						<strong>Institution number:</strong> 621
					</p>
					<p className="text-gray-600">
						<strong>Transit number:</strong> 16001
					</p>
					<p className="text-gray-600">
						<strong>SWIFT/BIC:</strong> TRWICAW1XXX (Use it only if you are making
						international payment outside the Canada)
					</p>

					<h4 className="text-md font-semibold text-gray-800 mt-4">
						Bank name and address
					</h4>
					<p className="text-gray-600">Wise Payments Canada Inc.</p>
					<p className="text-gray-600">99 Bank Street, Suite 1420</p>
					<p className="text-gray-600">Ottawa K1P 1H4</p>
					<p className="text-gray-600">Canada</p>
				</div>
			</div>
		</Layout>
	);
};

export default CompanyDetails;
